import React from 'react';
import '../css/review-card.css';
import ImageProgressive from './image-progressive';
import { shorten } from '../_ultility';
import { IReview } from 'src/_model/airtable.model';
import Image from './image';

export interface IReviewCardProps {
    id: number;
    item: IReview;
}

const ReviewCard = (props: IReviewCardProps) => {
    const reviewTextStyle = {
        flexBasis: !!props.item.PropertyPicture ? '73%' : '100%'
    };

    const score = props.item.Score > 5 ? Math.round((props.item.Score * 5) / 10) : props.item.Score;

    const personname = props.item.PersonName;

    const reviewDate = props.item.ReviewDate;

    return (
        <>
            <div className="card is-always-shadow review-box">
                <div className="header">
                    <div className="header-left">
                        {!!props.item.PersonPicture && (
                            <ImageProgressive
                                className="person-picture"
                                imageSrc={props.item.PersonPicture[0].url}
                                imageThumb={props.item.PersonPicture[0].thumbnails.small.url}
                            />
                        )}
                        <div className="header-name">
                            <span className="person-name">{personname}</span>
                            <span className="review-date">{reviewDate}</span>
                        </div>
                    </div>
                    <div className="header-right">
                        <div id={`review-rating-${props.id}`} className="rating">
                            {[1, 2, 3, 4, 5].map((n: number, index: number) => (
                                <span className="star" key={index}>
                                    <Image name={n <= score ? 'star-yellow' : 'star-none'} />
                                </span>
                            ))}
                        </div>
                        {!!props.item.ReviewSourcePicture && (
                            <ImageProgressive
                                className="review-source"
                                imageSrc={props.item.ReviewSourcePicture[0].url}
                                imageThumb={props.item.ReviewSourcePicture[0].thumbnails.small.url}
                            />
                        )}
                    </div>
                </div>

                <div className="content">
                    {!!props.item.PropertyPicture && !props.item.PropertyPicture[0].url.includes('no_pic') && (
                        <ImageProgressive
                            className="property-picture"
                            imageSrc={props.item.PropertyPicture[0].url}
                            imageThumb={props.item.PropertyPicture[0].thumbnails.small.url}
                        />
                    )}
                    {!!props.item.ReviewText && (
                        <span className="review-text" style={reviewTextStyle}>
                            {props.item.ReviewText !== '' ? shorten(props.item.ReviewText, 130) : ''}
                        </span>
                    )}
                </div>
            </div>
        </>
    );
};

export default ReviewCard;
