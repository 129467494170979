import { useStaticQuery, graphql } from 'gatsby';

export interface ISiteMetadata {
    site: {
        siteMetadata: {
            author: string;
            description: string;
            title: string;
            siteUrl: string;
        };
    };
}

export function getSEO() {
    const data = useStaticQuery<ISiteMetadata>(graphql`
        query {
            site {
                siteMetadata {
                    author
                    description
                    title
                    siteUrl
                }
            }
        }
    `);
    return data;
}
