import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img, { GatsbyImageFluidProps } from 'gatsby-image';

export interface IImageProps {
    name: string;
    style?: { [key: string]: string };
    className?: string;
    alt?: string;
}

export interface ImagesData {
    imgs: {
        nodes: {
            id: string;
            name: string;
            childImageSharp: GatsbyImageFluidProps;
        }[];
    };
}

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const Image = (props: IImageProps) => {
    const data = useStaticQuery<ImagesData>(graphql`
        query {
            imgs: allFile {
                nodes {
                    id
                    name
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `);
    const img = data.imgs.nodes.find((img) => img.name === props.name || img.id === props.name);

    if (!img?.childImageSharp?.fluid) {
        return <div>Picture {props.name} not found</div>;
    }

    return <Img className={props.className} style={props.style} fluid={img.childImageSharp.fluid} alt={props.alt} />;
};

export default Image;
