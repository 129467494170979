import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { translate, InjectedI18nProps, InjectedTranslateProps } from 'react-i18next';
import { TRANSLATION_DEFAULT } from '../../i18n';
import '../css/cookie-warning-popup.css';
import { getBoolean } from '../_ultility';

const info = require('../../build-info.ts');
const customerName = info.CustomerInfo.customer;
const customerConfig = require('../../customer/' + customerName + '/gatsby-configs');

export interface ICookiePopupProps extends InjectedI18nProps, InjectedTranslateProps {
    children?: any;
}

function getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

function setCookie(cname: string, cvalue: any, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function checkCookie(cookiename: string): boolean {
    const check = getCookie(cookiename);
    if (check === 'true') {
        return true;
    } else {
        return false;
    }
}

function checkCookieIsExist(cookiename: string): boolean {
    const check = getCookie(cookiename);
    if (check === '') {
        return false;
    } else {
        return true;
    }
}

const CookiePopup = (props: ICookiePopupProps) => {
    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const [isAgreedToPolicy, setIsAgreedToPolicy] = useState<boolean>(true);
    const [isAgreedToSocialPolicy, setIsAgreedToSocialPolicy] = useState<boolean>(false);

    useEffect(() => {
        const isAgreedToPolicyExist = checkCookieIsExist('isAgreedToPolicy');
        const isAgreedToSocialPolicyExist = checkCookieIsExist('isAgreedToSocialPolicy');
        console.log('useEffect', { isAgreedToPolicyExist, isAgreedToSocialPolicyExist });
        if (isAgreedToPolicyExist) {
            const isAgreedToPolicy = getBoolean(checkCookie('isAgreedToPolicy'));
            setIsAgreedToPolicy(isAgreedToPolicy);
            if ('ga' in window) {
                // @ts-ignore
                window.ga('set', 'anonymizeIp', 0);
            }
        }

        if (isAgreedToSocialPolicyExist) {
            const isAgreedToSocialPolicy = getBoolean(checkCookie('isAgreedToSocialPolicy'));
            setIsAgreedToPolicy(isAgreedToSocialPolicy);
            if ('ga' in window) {
                // @ts-ignore
                window.ga('set', 'anonymizeIp', 0);
            }
        }

        if (!isAgreedToPolicyExist && !isAgreedToSocialPolicyExist) {
            setIsShowPopup(true);
        } else {
            setIsShowPopup(false);
        }
    });

    const onAgreeAllOptions = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setCookie('isAgreedToPolicy', 'true', 60);
        setIsAgreedToPolicy(true);
        setCookie('isAgreedToSocialPolicy', 'true', 60);
        setIsAgreedToSocialPolicy(true);
        setIsShowPopup(false);

        if ('gtag' in window) {
            // @ts-ignore
            gtag('config', customerConfig.googleAnalyticsID, { anonymize_ip: false });
        }

        // disallow social tracking
        if ('fbq' in window) {
            // @ts-ignore
            fbq('consent', 'grant');
        }
    };

    const onAgreeSelected = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setCookie('isAgreedToPolicy', isAgreedToPolicy.toString(), 60);
        setCookie('isAgreedToSocialPolicy', isAgreedToSocialPolicy.toString(), 60);
        setIsAgreedToPolicy(isAgreedToPolicy);
        setIsAgreedToSocialPolicy(isAgreedToSocialPolicy);
        setIsShowPopup(false);

        if ('gtag' in window) {
            // @ts-ignore
            gtag('config', customerConfig.googleAnalyticsID, { anonymize_ip: false });
        }

        // disallow social tracking
        if ('fbq' in window) {
            // @ts-ignore
            fbq('consent', isAgreedToSocialPolicy ? 'grant' : 'revoke');
        }
    };

    return (
        <>
            {isShowPopup && (
                <div id="cookie-warning">
                    <h2>{props.t('COOKIE_WARNING_TITLE')}</h2>
                    <h3>{props.t('COOKIE_WARNING_TITLE2')}</h3>
                    <div className="cookie-warning-options">
                        <div>
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isAgreedToPolicy}
                                        onChange={() => {
                                            setIsAgreedToPolicy(!isAgreedToPolicy);
                                        }}
                                    />
                                </label>
                            </div>
                            <div>
                                <p>{props.t('COOKIE_WARNING_TEXT')}</p>
                            </div>
                        </div>
                    </div>
                    <h3>{props.t('COOKIE_WARNING_TITLE_OPTIONS')}</h3>
                    <div className="cookie-warning-options">
                        <div>
                            <div>
                                <label className="checkboxslider">
                                    <input
                                        type="checkbox"
                                        checked={isAgreedToSocialPolicy}
                                        onChange={() => {
                                            setIsAgreedToSocialPolicy(!isAgreedToSocialPolicy);
                                        }}
                                    />
                                </label>
                            </div>
                            <div>
                                <p>
                                    <b>{props.t('COOKIE_WARNING_OPTION1_TITLE')}</b>
                                    <br />
                                    {props.t('COOKIE_WARNING_OPTION1_TEXT')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="btn-cookie-container">
                        <div className="row align-items-top col-sm-12">
                            <div className="col-sm-12 col-md-6">
                                <Link to="/cookie-policy/" target="__blank">
                                    <button className="btn btn-secondary btn-cookie-info" title="Cookie policy">
                                        {props.t('COOKIE_BUTTON_TEXT_MORE_INFO')}
                                    </button>
                                </Link>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <button
                                    className="btn btn-primary btn-cookie-agree"
                                    title="I agree"
                                    onClick={onAgreeAllOptions}>
                                    {props.t('COOKIE_BUTTON_TEXT_AGREE')}
                                </button>
                                <a onClick={onAgreeSelected} style={{ marginTop: '5px' }}>
                                    <small>{props.t('COOKIE_BUTTON_TEXT_AGREE_PARTLY')}</small>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default translate(TRANSLATION_DEFAULT)(CookiePopup);
