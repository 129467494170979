import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import RenderConfigFE from '../../../src/components/render-config-fe';
import LazyLoad from 'react-lazyload';
import ReviewSlider from '../../../src/views/review-slider';
import CustomForm from '../components/custom-form';
import { getConfigFrontEnd } from '../../../src/components/use-config-fe';
import { IAirtableAttachment } from '../../../src/_model/airtable.model';
import CookiePopup from '../../../src/components/cookie-warning-popup';

const IndexPage = () => {
    const configMetadataImage = getConfigFrontEnd({ name: 'metadataImage' }) as IAirtableAttachment;

    return (
        <Layout>
            <CookiePopup />
            <SEO title="RECEVEZ L'EBOOK GRATUIT: 12 CONSEILS POUR VENDRE VOTRE MAISON PLUS RAPIDEMENT"
                description="RECEVEZ L'EBOOK GRATUIT: 12 CONSEILS POUR VENDRE VOTRE MAISON PLUS RAPIDEMENT"
                image={configMetadataImage.url} />
            <LazyLoad height={400} offset={100}>
                <section className="section section-custom">
                    <div className="bg padding marginBottom backgroundImage">
                        <RenderConfigFE name="BackgroundImage" type="image" />
                    </div>
                    <div className="advice container-fluid">
                        <div className="fluid is-always-shadow contact-card">
                            <RenderConfigFE className="title" name="12conseilsTitle" type="html" />
                            <div className="row tips-row">
                                <div className="col-sm-12 col-md-12 col-lg-3">
                                    <RenderConfigFE name="CustomFormImage" type="image" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-9">
                                    <CustomForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </LazyLoad>

            <LazyLoad height={400} offset={50}>
                <section className="section section-review">
                    <div className="review container-fluid">
                        <ReviewSlider />
                    </div>
                </section>
            </LazyLoad>

            <LazyLoad height={400} offset={100}>
                <section className="section section-article">
                    <div className="logo-image">
                        <RenderConfigFE name="LogoImageFooter" type="image" />
                    </div>
                    <div className="container-fluid">
                        <RenderConfigFE className="title" name="FooterTitle" type="html" />
                        <RenderConfigFE name="FooterContent2" type="html" />
                    </div>
                </section>
            </LazyLoad>
        </Layout>
    );
};

export default IndexPage;
