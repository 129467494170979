const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

console.log(`Using environment config: '${activeEnv}'`);

require('dotenv').config({
    path: `${__dirname}/.env.${activeEnv}`
});

export const CustomerInfo = {
    defaultLanguage: process.env.DEFAULT_LANGUAGE,
    ApiV2Token: process.env.APIV2,
    base: process.env.BASE,
    customer: process.env.CUSTOMER,
    pageTemplate:
        (process.env.PAGE_TEMPLATE === '{PAGE_TEMPLATE}' ? undefined : process.env.PAGE_TEMPLATE) ||
        'free-estimation-page',
    mailSend: process.env.EMAIL_SEND === '{EMAIL_SEND}' ? undefined : process.env.EMAIL_SEND,
    storySuccessSlider: process.env.STORY_SUCCESS_SLIDER || 'small'
};
