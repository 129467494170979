import { Link } from 'gatsby';
import React from 'react';
import RenderConfigFE from '../../../../src/components/render-config-fe';
import classNames from 'classnames';
import { getBoolean } from '../../../../src/_ultility';
import { getConfigFrontEnd } from '../../../../src/components/use-config-fe';

export interface IHeaderProps {
    children?: any;
}

const Header = (props: IHeaderProps) => {
    const configHeader1 = getConfigFrontEnd({ name: 'HeaderStyle1' });
    const configHeader2 = getConfigFrontEnd({ name: 'HeaderStyle2' });
    const configLogoLink = getConfigFrontEnd({ name: 'LogoLink' });

    const headerClass = classNames({
        'container-fluid': true,
        header1: getBoolean(configHeader1 || false) === true,
        header2: getBoolean(configHeader2 || false) === true
    });

    return (
        <section className="section-header">
            <div className={headerClass}>
                <div className="logo-image">
                    <Link to={configLogoLink} target="__blank">
                        <RenderConfigFE name="LogoImage" type="image" />
                    </Link>
                </div>
                <div className="customer-name">
                    <Link to={configLogoLink} target="__blank">
                        <RenderConfigFE name="LogoImage2" type="image" />
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Header;
