import React from 'react';
import '../css/image-progressive.css';

interface IProgressiveImageComponentProps {
    imageSrc: string;
    imageThumb?: string;
    className?: string;
    id?: string;
    width?: number | string;
    height?: number | string;
    alt?: string;
    style?: { [key: string]: string };
    [key: string]: any;
}

interface IProgressiveImageComponentState {}

class ProgressiveImageComponent extends React.Component<
    IProgressiveImageComponentProps,
    IProgressiveImageComponentState
> {
    private elementThumb: HTMLImageElement | null;
    private elementSrc: HTMLImageElement | null;
    private elementWrapper: HTMLDivElement | null;

    constructor(props: IProgressiveImageComponentProps) {
        super(props);
        this.elementThumb = null;
        this.elementSrc = null;
        this.elementWrapper = null;
    }

    getImageThumb(image: string) {
        if (image.includes('cloud-storage.omnicasa.com')) {
            return `${image}?scale=0.1`;
        }
        return image;
    }

    componentDidMount() {
        if (!!window.IntersectionObserver) {
            this.createObserver();
        } else {
            this.loadImage();
        }
    }

    loadImage() {
        if (!!this.elementSrc && !!this.elementThumb) {
            this.elementThumb.addEventListener('load', () => {});

            this.elementSrc.addEventListener('load', () => {
                if (!!this.elementThumb) {
                    this.elementThumb.classList.add('hide');
                }
            });

            this.elementSrc.addEventListener('error', () => console.error('ProgressiveImageDirective original error'));
            this.elementThumb.addEventListener('error', () => console.error('ProgressiveImageDirective thumb error'));
            this.elementSrc.src = this.elementSrc.dataset.url!;
        }
    }

    handleIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (!entry.isIntersecting) {
                return;
            } else {
                this.loadImage();
                if (!!this.elementWrapper) {
                    observer.unobserve(this.elementWrapper);
                }
            }
        });
    }

    createObserver() {
        const options = {
            root: null,
            threshold: 0
        };

        const observer = new IntersectionObserver(this.handleIntersect.bind(this), options);
        observer.observe(this.elementWrapper!);
    }

    render() {
        const { className, id, imageSrc, imageThumb, style } = this.props;
        return (
            <>
                <div ref={(c) => (this.elementWrapper = c)} className={className + ' ' + 'progressive-loading'}>
                    <div className="progressive-loading-wrapper">
                        <img
                            style={style}
                            id={id}
                            ref={(c) => (this.elementSrc = c)}
                            className={'original'}
                            data-url={imageSrc}
                            alt={`${this.props.alt || 'progressive-image-original'}`}
                        />
                        <img
                            ref={(c) => (this.elementThumb = c)}
                            className="thumb"
                            src={imageThumb || this.getImageThumb(imageSrc)}
                            alt={`${this.props.alt || 'progressive-image-thumb'}`}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default ProgressiveImageComponent;
