import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import { PageProps } from 'gatsby';

interface IPageProps {
    files: string[]
}

const FilesPage: React.FC<PageProps<{}, IPageProps>> = ({ pageContext, path }) => {
    const { files } = pageContext;
    console.log('files');
    return (
        <Layout>
            <SEO title="files" />
            <div className='container-fluid' style={{ height: '500px', marginTop: '2rem' }}>
                <ul>
                    {files.map((file, index) => <li key={index}><a href={`/files/` + file} target='__blank'>{file}</a></li>)}
                </ul>
            </div>
        </Layout>
    );
};

export default FilesPage;
