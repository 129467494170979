import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import { CustomerInfo } from '../build-info';
export const TRANSLATION_DEFAULT = 'translations';

const en = require('./en.json');
const fr = require('./fr.json');
const nl = require('./nl.json');

const enCus = require('./en-cus.json');
const nlCus = require('./nl-cus.json');
const frCus = require('./fr-cus.json');

i18n.use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        resources: {
            en: {
                [TRANSLATION_DEFAULT]: { ...en, ...enCus }
            },
            nl: {
                [TRANSLATION_DEFAULT]: { ...nl, ...nlCus }
            },
            fr: {
                [TRANSLATION_DEFAULT]: { ...fr, ...frCus }
            }
        },
        lng: CustomerInfo.defaultLanguage,
        fallbackLng: CustomerInfo.defaultLanguage,
        // have a common namespace used around the full app
        ns: [TRANSLATION_DEFAULT],
        defaultNS: TRANSLATION_DEFAULT,
        debug: false,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // not needed for react!!
        },
        react: {
            wait: true
        }
    });

export default i18n;
