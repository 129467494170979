import React from 'react';
import RenderConfigFE from '../../../../src/components/render-config-fe';
import { getConfigFrontEnd } from '../../../../src/components/use-config-fe';

export interface IFooterProps {
    children?: any;
}

const Footer = (props: IFooterProps) => {
    const configFooterCopyright = getConfigFrontEnd({ name: 'FooterCopyright' });

    if (!!configFooterCopyright) {
        return (
            <section className="section-footer">
                <div className="container-fluid">
                    <RenderConfigFE className="copy-right" name="FooterCopyright" type="html" />
                </div>
            </section>
        );
    }
    return null;
};

export default Footer;
