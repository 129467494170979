import lodash from 'lodash';

// Send a message to the parent
export function sendMessage(msg: { type: string; mess: string }) {
    // Make sure you are sending a string, and to stringify JSON
    window.parent.postMessage(msg, '*');
}

export function roundToFixed(num: any) {
    return Math.round(num * 100) / 100;
}

export function removeParamsNULL(params: any) {
    const newParams: any = { ...params };
    for (const propName in newParams) {
        if (
            newParams[propName] === null ||
            newParams[propName] === undefined ||
            newParams[propName] === '' ||
            newParams[propName] === '-1'
        ) {
            delete newParams[propName];
        }
    }
    return newParams;
}

export function stringValidJson(str: string): boolean {
    try {
        if (
            /^[\],:{}\s]*$/.test(
                str
                    .replace(/\\['\\\/bfnrtu]/g, '@')
                    .replace(/'[^'\\\n\r]*'|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                    .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
            )
        ) {
            //the json is ok
            return true;
        } else {
            //the json is not ok
            return false;
        }
    } catch (ex) {
        console.error(ex);
        return false;
    }
}

export function PrepareTreeArray(arr: any, idField: string = 'ID', parentIdField: string = 'ParentID') {
    let tree = [];
    const mappedArr = [];
    let arrElem = [];
    let mappedElem = [];

    for (let i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        mappedArr[arrElem[idField]] = arrElem;
        mappedArr[arrElem[idField]]['children'] = [];
    }

    for (const id in mappedArr) {
        if (mappedArr.hasOwnProperty(id)) {
            mappedElem = mappedArr[id];
            if (mappedElem[parentIdField]) {
                if (typeof mappedArr[mappedElem[parentIdField]] !== 'undefined') {
                    mappedArr[mappedElem[parentIdField]]['children'].push(mappedElem);
                }
            } else {
                tree.push(mappedElem);
            }
        }
    }

    tree = lodash.orderBy(tree, ['label'], ['asc']);
    tree.forEach((item: any) => {
        item.children = lodash.orderBy(item.children, ['label'], ['asc']);
    });

    return tree;
}

export function convertArrayStringToInt(arr: any[]) {
    return arr.map((a: any) => {
        if (typeof a === 'string') return parseInt(a, 10);
        return a;
    });
}

export function convertArrayIntToString(arr: any[]) {
    return arr.map((a) => {
        if (typeof a === 'number') return a.toString();
        return a;
    });
}

export function getLocalStorage(item: string) {
    if (!window) {
        return;
    }
    const data = window.localStorage.getItem(item) as string;

    if (stringValidJson(data)) {
        return JSON.parse(data);
    }

    return data;
}

export function setLocalStorage(item: string, data: any) {
    if (!window) {
        return;
    }
    window.localStorage.setItem(item, data);
    return data;
}

export function removeLocalStorage(item: string) {
    if (!window) {
        return;
    }
    window.localStorage.removeItem(item);
}

// addEventListener support for IE8
export function bindEvent(element: any, eventName: any, eventHandler: any) {
    if (element.addEventListener) {
        element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
        element.attachEvent('on' + eventName, eventHandler);
    }
}

// removeEventListener support for IE8
export function removeEvent(element: any, eventName: string, eventHandler: any) {
    if (element.removeEventListener) {
        element.removeEventListener(eventName, eventHandler);
    } else if (element.attachEvent) {
        element.detachEvent('on' + eventName, eventHandler);
    }
}

/**
 * Delay after milisecond.
 *
 * @export
 * @param {number} timeout
 * @returns {Promise<any>}
 */
export function delay(timeout: number): Promise<any> {
    return new Promise<any>((resolve) => {
        setTimeout(resolve, timeout);
    });
}

/**
 * Retry with number & interval
 *
 * @export
 * @template T
 * @param {number} number
 * @param {(number | number[])} interval
 * @param {(Promise<T> | (() => Promise<T>))} action
 * @returns {(Promise<T | undefined>)}
 */
export async function retry<T>(
    number: number,
    interval: number | number[],
    action: () => Promise<T>,
    again?: (error: Error) => boolean
): Promise<T> {
    for (let tried = 0; tried < number; ++tried) {
        try {
            return await action();
        } catch (error) {
            if (!!again && !again(error)) {
                throw error;
            }
            if (tried === number - 1) {
                throw error;
            }
            if (interval instanceof Array) {
                if (tried < interval.length) {
                    await delay(interval[tried]);
                }
            } else {
                await delay(interval);
            }
        }
    }
    throw new RangeError('Invalied retry number.');
}

export const asyncLoading = (target: any, methodName: string, descriptor: TypedPropertyDescriptor<any>) => {
    const originalMethod = descriptor.value; // Save method

    descriptor.value = async function (...args: any[]): Promise<any> {
        Object.assign(this, { coLoading: true });

        const data = await originalMethod.apply(this, args);

        Object.assign(this, { coLoading: false });

        return data;
    };

    return descriptor;
};

export function getLanguage(code: 'en' | 'nl' | 'fr' | string) {
    switch (code) {
        case 'en':
            return 3;
        case 'fr':
            return 2;
        case 'en':
            return 1;
        default:
            return 1;
    }
}

export function getBoolean(value: string | boolean | number) {
    if (typeof value === 'string') value = value.trim();
    switch (value) {
        case 0:
        case '0':
        case 'false':
        case 'no':
        case false:
            return false;
        case 1:
        case '1':
        case 'true':
        case 'yes':
        case true:
            return true;
        default:
            return false;
    }
}

export function shorten(str: string, maxLen: number, separator = ' ') {
    if (str === '' || str === undefined || str === null) return '';
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';
}
